import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ConfigService } from 'src/app/config/config.service';
import { ApiService } from '../../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss']
})
export class MonthpickerComponent implements OnInit {

  public date: Date = new Date()
  public start_date: any = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
  public end_date: any = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
  public product_type_id
  public product_value

  monthPicker(event) {
    let stDate = new Date(event)
    this.end_date = new Date(stDate.getFullYear(), stDate.getMonth() + 1, 0)
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    // private config: ConfigService,
    private api: ApiService
  ) {

    // this.product_value = this.config.getText("ALl_Products")
    this.product_value = environment.ALl_Products
    this.product_type_id = this.product_value.product_type

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['start_date']) {
        this.start_date = new Date(params['start_date'])
        this.end_date = new Date(params['end_date'])
      }
      if (params['product_type_id']) {
        this.product_type_id = params['product_type_id']
      }
    });
  }

  ngOnInit() {
  }
}
