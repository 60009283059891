import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from "rxjs";
// import { ConfigService } from '../config/config.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
	public loggedIn = new BehaviorSubject<boolean>(false);
	public userExecutiveCode = new BehaviorSubject<string>('');
	private contest_value: any;
	private user: {};

	private BASE_URL: string = '/api/v1/accounts';
	private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
	public is_logged_in: Observable<boolean>
	constructor(private http: Http, private router: Router,
		// private config: ConfigService
	) {
		this.logOut = this.logOut.bind(this);
	}

	get isLoggedIn() {
		return this.loggedIn.asObservable();
	}

	getUserExecutiveCode(data: any) {
		this.userExecutiveCode.next(data);
	}

	setUser = (user) => {

		if (user && user.token) {
			localStorage.setItem('ta_token', user.token);
			this.loggedIn.next(true);
			localStorage.setItem('ta_user_type', user.user_type);
			localStorage.setItem('ta_user_email', user.email);
			localStorage.setItem('ta_user_name', user.first_name);
			localStorage.setItem('ta_user_last', user.last_name);
			localStorage.setItem('tier', user.tier);
			localStorage.setItem('registration_date', user.registration_date);
			localStorage.setItem('code', user.username);

			this.getUserExecutiveCode(user.username);

		} else {
			this.logOut()
		}
	}



	logOut() {
		localStorage.clear();
		localStorage.removeItem('ta_token');
		localStorage.removeItem('ta_user_type');
		localStorage.removeItem('ta_partner_id');
		localStorage.removeItem('ta_user_email');
		localStorage.removeItem('ta_user_name');
		localStorage.removeItem('ta_user_last');
		localStorage.removeItem('registration_date');
		localStorage.removeItem('tier');
		localStorage.removeItem('code');
		localStorage.removeItem('beta_features');
		localStorage.removeItem('breadcrums');

		this.loggedIn.next(false);
		// window.location.href = this.config.get("amsURL") + "?next=" + this.config.get("baseUrl") + this.router.routerState.snapshot.url
		window.location.href = environment.amsURL + "?next=" + environment.baseUrl + this.router.routerState.snapshot.url
	}

	registerDate() {
		let end_date = new Date();
		let registerDate_s = localStorage.getItem('registration_date');
		let register_Date_m = new Date(registerDate_s);
		let start_date_s = register_Date_m.getTime()
		let end_date_s = end_date.getTime()
		let days = Math.round((end_date_s - start_date_s) / (1000 * 60 * 60 * 24)) + 1
		if (days <= 15) {
			localStorage.setItem('contest_value', 'true');
		} else {
			localStorage.setItem('contest_value', 'false');
		}
	}

	checkLogin() {
		let id = localStorage.getItem('ta_token')
		if (id && (id != 'undefined') && (id != 'null')) {
			return true
		} else {
			return false
		}
	}

	isPartner() {
		let id: string = localStorage.getItem('ta_user_type');
		if (id == '2') {
			return true
		} else {
			return false
		}
	}
	isAggregator() {
		return false
	}

	GetUserToken() {
		let token = localStorage.getItem('ta_token');
		if (token && (token != 'undefined') && (token != 'null')) {
			return token
		} else {
			return false
		}
	}
	GetUserName() {
		let name = localStorage.getItem('ta_user_name');
		if (name && (name != 'undefined') && (name != 'null')) {
			return name
		} else {
			return false
		}
	}

	GetUserCode() {
		let name = localStorage.getItem('code');
		if (name && (name != 'undefined') && (name != 'null')) {
			return name
		} else {
			return false
		}
	}

	GetBetaFeature() {
		let beta_features = localStorage.getItem('beta_features')
		if (beta_features && (beta_features == 'false')) {
			return false;
		} else {
			return true;
		}
	}
}
