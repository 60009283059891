import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ConfigService } from 'src/app/config/config.service';
import { ApiService } from '../../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
    @Input() showInsurerFilter: boolean = false;
    @Input() showStateFilter: boolean = false;
    @Input() showBusinessTypeFilter: boolean = false;
    public date: Date = new Date()
    public start_date: any = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
    public end_date: any = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
    public product_type_id;
    public insurer_id;
    public business_type_id;
    public state_id;
    public product_value;
    public Insurers: Array<any> = ['Select Insurer'];
    public States: Array<any> = ['Select State'];
    public BusinessTypes: Array<any> = [];
    public selectedInsurers: Array<any> = ['Select Insurer'];
    public selectedStates: Array<any> = ['Select State'];
    public selectedBusiness: Array<any> = ['Select Business Type'];
    public allInsurers: boolean = false;
    public allStates: boolean = false;
    public allBusinessTypes: boolean = false;
    public include_list: Array<any> = ['insurer', 'state'];
    public insurers;
    public states;
    public business_type;

    constructor(
        private activatedRoute: ActivatedRoute,
        // private config: ConfigService,
        private api: ApiService
    ) {

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['start_date'] && params['start_date']) {
                this.start_date = new Date(params['start_date'])
                this.end_date = new Date(params['end_date'])
            }
            if (params['product_type_id']) {
                this.product_type_id = params['product_type_id']
            }
            if (params.start_date && params.end_date) {
                this.start_date = new Date(params.start_date);
                this.end_date = new Date(params.end_date);
            }
        });
        let insurer_list = localStorage.getItem("insurers")
        let state_list = localStorage.getItem("states")
        let business_types_list = localStorage.getItem("business_type")

        if (insurer_list) {
            this.insurer_id = insurer_list
        }
        if (state_list) {
            this.state_id = state_list
        }
        if (business_types_list) {
            this.business_type_id = business_types_list
        }


        // this.product_value = this.config.getText("ALl_Products")
        // this.product_value = environment.ALl_Products
        // this.product_type_id = this.product_value.product_type

        this.api.getFiltersList(this.start_date, this.end_date, this.product_type_id, { 'include': this.include_list }).then((response) => {
            this.Insurers = response['insurer']
            this.States = response["state"]

        })
        // this.BusinessTypes = this.config.getText("businessTypes");
        this.BusinessTypes = environment.businessTypes
    }




    onChangeInsurer(name: string, isChecked: boolean) {
        if (this.insurer_id) {
            if (isChecked) {
                this.insurer_id = this.insurer_id.concat(name);
                this.selectedInsurers = this.selectedInsurers.concat(name).filter(e => e !== 'Select Insurer');
            } else {
                this.insurer_id = this.insurer_id.filter(e => e != name)
                this.selectedInsurers = this.selectedInsurers.filter(n => !name.includes(n))
                if (!(this.selectedInsurers.length > 0)) {
                    this.selectedInsurers = ['Select Insurer']
                }
                this.allInsurers = false
            }
        }
        else {
            this, this.insurer_id = name
            this.selectedInsurers = this.selectedInsurers.concat(name).filter(e => e !== 'Select Insurer');
        }
    }

    checkUncheckInsurer(event) {
        const checked = event.target.checked;
        this.insurer_id = []
        if (checked == true) {
            for (let i = 0; i < this.Insurers.length; i++) {
                this.insurer_id = this.insurer_id.concat(this.Insurers[i].name)
                this.selectedInsurers = this.selectedInsurers.concat(this.Insurers[i].name).filter(e => e !== 'Select Insurer');
                this.Insurers[i].isSelected = checked
                this.allInsurers = checked
            }
        }
        else {
            this.Insurers.forEach(item => item.isSelected = checked);
            this.insurer_id = []
            this.selectedInsurers = ['Select Insurer']
        }
    }

    onChangeBusiness(name: string, id: any, isChecked: boolean) {
        if (this.business_type_id) {
            if (isChecked) {
                this.business_type_id = this.business_type_id.concat(id);
                this.selectedBusiness = this.selectedBusiness.concat(name).filter(e => e !== 'Select Business Type');
            } else {
                this.business_type_id = this.business_type_id.filter(e => e != id)
                this.selectedBusiness = this.selectedBusiness.filter(n => !name.includes(n))
                if (!(this.selectedBusiness.length > 0)) {
                    this.selectedBusiness = ['Select Business Type']
                }
                this.allBusinessTypes = false
            }
        }
        else {
            this.business_type_id = id
            this.selectedBusiness = this.selectedBusiness.concat(name).filter(e => e !== 'Select Business Type');
        }
    }

    checkUncheckBusiness(event) {
        const checked = event.target.checked;
        this.business_type_id = []
        if (checked == true) {
            for (let i = 0; i < this.Insurers.length; i++) {
                this.business_type_id = this.business_type_id.concat(this.BusinessTypes[i].id)
                this.selectedBusiness = this.selectedBusiness.concat(this.BusinessTypes[i].name).filter(e => e !== 'Select Business Type');
                this.BusinessTypes[i].isSelected = checked
                this.allBusinessTypes = checked
            }
        }
        else {
            this.BusinessTypes.forEach(item => item.isSelected = checked);
            this.business_type_id = []
            this.selectedBusiness = ['Select Business Type']
        }
    }

    onChangeState(name: string, isChecked: boolean) {
        if (this.state_id) {
            if (isChecked) {
                this.state_id = this.state_id.concat(name);
                this.selectedStates = this.selectedStates.concat(name).filter(e => e !== 'Select State');
            } else {
                this.state_id = this.state_id.filter(e => e != name)
                this.selectedStates = this.selectedStates.filter(n => !name.includes(n))
                if (!(this.selectedStates.length > 0)) {
                    this.selectedStates = ['Select State']
                }
                this.allStates = false
            }
        }
        else {
            this.state_id = name
            this.selectedStates = this.selectedStates.concat(name).filter(e => e !== 'Select State');
        }
    }

    checkUncheckState(event) {
        const checked = event.target.checked;
        this.state_id = []
        if (checked == true) {
            for (let i = 0; i < this.States.length; i++) {
                this.state_id = this.state_id.concat(this.States[i].name)
                this.selectedStates = this.selectedStates.concat(this.States[i].name).filter(e => e !== 'Select State');
                this.States[i].isSelected = checked
                this.allStates = checked
            }
        }
        else {
            this.States.forEach(item => item.isSelected = checked);
            this.state_id = []
            this.selectedStates = ['Select State']
        }
    }

    checkForInsurers() {
        let result;
        let count = 0;
        if (this.insurer_id) {
            this.insurer_id = this.insurer_id.split(',')
            for (let i = 0; i < this.Insurers.length; i++) {
                result = this.insurer_id.includes(this.Insurers[i].name)
                if (result) {
                    this.Insurers[i].isSelected = result
                    this.selectedInsurers = this.selectedInsurers.concat(this.Insurers[i].name).filter(e => e !== 'Select Insurer');
                    count++;
                }
            }
            this.insurer_id = this.Insurers.reduce((a, o) => (o.isSelected && a.push(o.name), a), []);
        }
        if (count == this.Insurers.length) {
            this.allInsurers = true
            this.selectedInsurers = ['All Insurers Selected']
        }
        else {
            this.allInsurers = false

        }
    }

    checkForStates() {
        let result;
        let count = 0;
        if (this.state_id) {
            this.state_id = this.state_id.split(',')
            for (let i = 0; i < this.States.length; i++) {
                result = this.state_id.includes(this.States[i].name)
                if (result) {
                    this.States[i].isSelected = result
                    this.selectedStates = this.selectedStates.concat(this.States[i].name).filter(e => e !== 'Select State');
                    count++;
                }
            }
            this.state_id = this.States.reduce((a, o) => (o.isSelected && a.push(o.name), a), []);
        }
        if (count == this.States.length) {
            this.allStates = true
            this.selectedStates = ['All States Selected']
        }
        else {
            this.allStates = false
        }
    }

    checkForBusiness() {
        let result;
        let count = 0;
        if (this.business_type_id) {
            this.business_type_id = this.business_type_id.split(',')
            for (let i = 0; i < this.BusinessTypes.length; i++) {
                result = this.business_type_id.includes(this.BusinessTypes[i].id.toString())
                if (result) {
                    this.BusinessTypes[i].isSelected = result
                    this.selectedBusiness = this.selectedBusiness.concat(this.BusinessTypes[i].name).filter(e => e !== 'Select Business Type');
                    count++;
                }
            }
            this.business_type_id = this.BusinessTypes.reduce((a, o) => (o.isSelected && a.push(o.id), a), []);
        }
        if (count == this.BusinessTypes.length) {
            this.allBusinessTypes = true
            this.selectedBusiness = ['All Business Selected']
        }
        else {
            this.allBusinessTypes = false
        }
    }

    onSubmit() {
        if (this.insurer_id) {
            localStorage.setItem("insurers", this.insurer_id)
        }
        if (this.state_id) {
            localStorage.setItem("states", this.state_id)
        }
        if (this.business_type_id) {
            localStorage.setItem("business_type", this.business_type_id)
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.checkForInsurers()
            this.checkForStates()
            this.checkForBusiness()
        }, 500);
    }
}
