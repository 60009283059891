import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Component } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { ApiService } from './services/api.service'
import { AuthService } from './services/auth.service'
import { HttpService } from './services/http.service'
import { WindowRef } from './services/window.service'
import { HttpModule } from '@angular/http';
// import { ConfigService } from './config/config.service'
import { MaterialModule } from './shared/modules/material/material.module';
import { LoaderService } from './shared/loader/loader.service'
import { DialogComponent } from './shared/dialogs/dialog/dialog.component';
import { TableComponent } from './components/table/table.component';
import { ReportsComponent } from './components/reports/reports.component';
import { TeamComponent } from './components/team/team.component';
import { requesterPanelComponent } from './components/requesterPanel/requesterPanel.component';
import { ReporteeListComponent } from './components/reporteelist/reporteelist.component';
import { DatepickerComponent } from './shared/datepicker/datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { RouterModule, RouterStateSnapshot } from '@angular/router';
import { routes } from '../app/app-routing.module'
import { SidebarService } from './components/sidebar/sidebar.services';
import { ProductFilterComponent } from './shared/product-filter/product-filter.component';
import { MatListModule, MatSelectModule, MatButtonModule } from '@angular/material';
import { FooterComponent } from './shared/footer/footer.component';
import { AnalyticsDashboardComponent } from './components/analytics-dashboard/analytics-dashboard.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { MonthpickerComponent } from './shared/monthpicker/monthpicker.component';
import { AdvisorFinderDashboardComponent } from './components/advisor-finder-dashboard/advisor-finder-dashboard.component';
import { CallHistoryComponent } from './modal-component/call-history/call-history.component';
import { TrmDashboardComponent } from './components/trm-dashboard/trm-dashboard.component';
import { ConnectCallComponent } from './modal-component/connect-call/connect-call.component';
import { MappedPartnersComponent } from './components/mapped-partners/mapped-partners.component';
import { IflsLifeRenewalComponent } from './components/ifls-life-renewal/ifls-life-renewal.component';
import { MatTooltipModule } from '@angular/material/tooltip';

// export function configServiceFactory(configService: ConfigService): Function {
//   return () => configService.load();
// }
// export function configTextServiceFactory(configService: ConfigService): Function {
//   return () => configService.loadText();
// }

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        HeaderComponent,
        DialogComponent,
        TableComponent,
        ReportsComponent,
        TeamComponent,
        requesterPanelComponent,
        ReporteeListComponent,
        DatepickerComponent,
        SidebarComponent,
        HomeComponent,
        ErrorComponent,
        ProductFilterComponent,
        FooterComponent,
        AnalyticsDashboardComponent,
        BreadcrumbsComponent,
        MonthpickerComponent,
        AdvisorFinderDashboardComponent,
        CallHistoryComponent,
        TrmDashboardComponent,
        ConnectCallComponent,
        MappedPartnersComponent,
        IflsLifeRenewalComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpModule,
        MaterialModule,
        FormsModule,
        RouterModule.forRoot(routes, {}),
        MatSelectModule,
        MatListModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatTooltipModule,
    ],
    providers: [
        LoaderService, AuthService, ApiService, HttpService, DatePipe, WindowRef, SidebarService, 
        // ConfigService,
        // {
        // provide: APP_INITIALIZER,
        // useFactory: configServiceFactory,
        // deps: [ConfigService],
        // multi: true
        // }, {
        // provide: APP_INITIALIZER,
        // useFactory: configTextServiceFactory,
        // deps: [ConfigService],
        // multi: true
        // },
    ],
    entryComponents: [
        DialogComponent,
        CallHistoryComponent,
        ConnectCallComponent
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
