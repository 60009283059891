import { Component, OnInit, Input, ViewChild, NgZone, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';

@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: './analytics-dashboard.component.html',
    styleUrls: ['./analytics-dashboard.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class AnalyticsDashboardComponent implements OnInit {

    public loading: Boolean = true;
    public title = 'Analytics report';
    displayedColumns: string[];
    response: any;
    @Input() showPicker = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public showPagination = true;
    public start_date: any;
    public end_date: any;
    public sortResponse: any;
    public paginationList: Array<any> = []

    applyFilter(filterValue: string) {
        this.sortResponse.filter = filterValue.trim().toLowerCase();
    }

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        // private config: ConfigService,
        private auth: AuthService,
        private domSanitizer: DomSanitizer,
        private ngZone: NgZone,
        private dialog: MatDialog,
        private datePipe: DatePipe
    ) {

        const crntDate = new Date();
        const setStartDate =  crntDate.setDate(crntDate.getMonth() - 30);
        this.start_date =  this.datePipe.transform(new Date(  this.api.getDate(new Date(setStartDate))), 'dd/MM/yyyy');
        this.end_date =   this.datePipe.transform(new Date(  this.api.getDate(new Date())), 'dd/MM/yyyy');

        this.auth.isLoggedIn.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.api.getAnalyticsInformation(this.start_date, this.end_date).subscribe((response) => {
                    this.loading = true;
                    this.response = response;
                    console.log('this is response', this.response);
                    if (this.response.length > 0) {

                       

                        this.response.forEach((element, key) => {
                            this.displayedColumns = Object.keys(this.response[key]);
                            console.log('this is dsiplayed columns', this.displayedColumns);

                        });

                        this.loading = false;

                        /**
                         * Insital search as obswrvabel
                         */

                        const tableData = [];
                        let tableRows;
                        let tableDataIndex ;
                        if (this.response) {
                            tableDataIndex = this.response;
                            for (let i = 0 ; i < tableDataIndex.length; i++) {

                                tableData[i] = {};
                                tableData[i] = tableDataIndex[i];

                            }
                        }

                        tableRows = tableData;
                        console.log('table rows is', tableRows);


                         /**
                          * end of initail start
                          */


                        this.sortResponse = new MatTableDataSource(tableRows);
                        setTimeout(() => {
                           this.sortResponse .paginator = this.paginator;
                           this.sortResponse .sort = this.sort;
                        })
                         /**
                         * pagination list 
                         */

                        if (this.paginationList.indexOf(  this.sortResponse ) > -1) {
                            this.showPagination = false;
                        }
                         
                    }

                });
            }
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.start_date = new Date(params.start_date);
            this.end_date = new Date(params.end_date);
        });

    }

    /**
     * Search in table only
     */



    getAnalyticsInfo() {

        /**
         * Initate api for analytics
         */
        this.loading = true;
        this.api.getAnalyticsInformation(this.start_date, this.end_date).subscribe((response) => {

            if (response) {
                this.response = response;
                if (this.response.length > 0) {

                    for (const [key] of this.response) {

                        this.displayedColumns = Object.keys(this.response[key]);

                    }
                    this.loading = false;
                }
            }

        });
    }

    ngOnInit() {
    }

}
