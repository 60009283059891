
import { Component, HostListener, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
// import { ConfigService } from '../../config/config.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { PivotTodataService } from '../../services/pivot-todata.service';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import * as Papa from 'papaparse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {
  datasource: MatTableDataSource<any>;
  public response: any
  public displayedColumns: any
  public title: string = ''
  public date: Date = new Date()
  public start_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
  public end_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
  public loading: boolean = true
  public pivotHtml: any = false
  public second
  public headers: any
  public showPagination: boolean = true
  public paginationList: Array<any> = []
  public partnerID: string
  public inTheZone: boolean = true
  public showRs
  public showArr
  private screenWidth
  public showGrowth
  public product_type_id
  public product_value
  public product
  public show_disclaimer: boolean
  public diclaimer: string
  public hide_colms: Array<any> = []
  public report_send_acc: boolean
  public report_download_access: boolean
  public show_send_button: boolean
  public show_download_button: boolean
  public report_data: boolean = false
  public filter_arr: Array<any> = []
  public allCols: any
  public selectedValue: string
  public col_show: any
  public filter_flag: boolean = false
  public month_datepicker: any
  public show_month_datepicker: boolean = false
  public salesPromotionUrl: any
  public show_download_report: boolean = false
  public showInsurerFilter: boolean = false;
  public showStateFilter: boolean = false;
  public showBusinessTypeFilter: boolean = false;
  public baseUrl: string
  @Input() role: any
  @Input() id: number
  @Input() accountId: string
  @Input() showPicker: boolean
  @Input() data
  @Input() show_footer: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.response.filter = filterValue.trim().toLowerCase();
  }

  dropDownFilter(filterValue: string) {
    this.displayedColumns = this.allCols
    this.displayedColumns = this.displayedColumns.filter(
      (x: any) => x.startsWith(filterValue)
    )
    // .map(item => item.replace(filterValue + '_', ''));
    this.displayedColumns.unshift("User Id", "Name")
  }

  constructor(
    private domSanitizer: DomSanitizer,
    public api: ApiService,
    private activatedRoute: ActivatedRoute,
    private ptot: PivotTodataService,
    // private config: ConfigService,
    private auth: AuthService,
    private ngZone: NgZone,
    private dialog: MatDialog
  ) {

    // this.product_value = this.config.getText("ALl_Products")
    this.product_value = environment.ALl_Products
    this.product_type_id = this.product_value.product_type
    this.activatedRoute.queryParams.subscribe(params => {

      if (params['start_date'] && params['end_date']) {
        this.start_date = params['start_date']
        this.end_date = params['end_date']
      }
      if (params['product_type_id']) {
        this.product_type_id = params['product_type_id']
      }
    });
    // this.headers = this.config.getText("tableHeader")
    this.headers = environment.tableHeader
    // this.paginationList = this.config.getText("hidePagination")
    this.paginationList = environment.hidePagination
    // this.showRs = this.config.getText("showRupee")
    this.showRs = environment.showRupee
    // this.showArr = this.config.getText("showArrow")
    this.showArr = environment.showArrow
    // this.showGrowth = this.config.getText("showGrowth");
    this.showGrowth = environment.showGrowth
    // this.show_footer = this.config.getText("foot_note");
    this.show_footer = environment.foot_note
    // this.hide_colms = this.config.getText("hide_colms");
    this.hide_colms = environment.hide_colms
    // this.col_show = this.config.getText("col_show");
    this.col_show = environment.col_show
    // this.month_datepicker = this.config.getText("month_date_picker")
    this.month_datepicker = environment.month_date_picker
    // this.salesPromotionUrl = this.config.get("salesPromotionUrl")
    this.salesPromotionUrl = environment.salesPromotionUrl
    this.baseUrl = environment.baseUrl

    this.windowFunction();
    this.getScreenSize();
  }


  ngOnChanges() {
    if (this.data) {
      // User has report send access
      this.report_send_acc = this.data['user_email_report_access']
      this.report_download_access = this.data['user_download_report_access']
      this.showBusinessTypeFilter = this.data["business_type_filter"]
      this.showInsurerFilter = this.data["insurer_filter"]
      this.showStateFilter = this.data["state_filter"]
      this.renderTable(this.data)
    }
  }

  redirect_sales_promotion = (event) => {
    let start_date = new Date(this.start_date)
    let end_date = new Date(this.end_date)
    let start_date_str = start_date.getDate() + '/' + (start_date.getMonth() + 1) + '/' + start_date.getFullYear()
    let end_date_str = end_date.getDate() + '/' + (end_date.getMonth() + 1) + '/' + end_date.getFullYear()

    let salesPromotionUrl = this.salesPromotionUrl + '/?rm_code=' + event.currentTarget.id + "&start_date=" + start_date_str + "&end_date=" + end_date_str

    window.open(salesPromotionUrl)
  }

  renderTable = (response) => {
    this.loading = true
    this.response = ""
    // let response = responsed.reports["17"]
    if (response) {
      this.loading = false
      let tableRows
      this.title = response["report_name"]
      //Month filter
      if (this.month_datepicker.includes(this.title)) {
        this.show_month_datepicker = true
      }
      // Report has email option
      this.show_send_button = response["email_report"]
      this.show_download_button = response["download_report"]
      if (this.show_download_button && this.report_download_access) {
        this.show_download_report = true
      } else {
        this.show_download_report = false
      }
      if (this.show_footer[this.title]) {
        this.diclaimer = this.show_footer[this.title]
      }
      // Drop Down filter
      if (this.col_show[this.title]) {
        this.filter_flag = true
        this.filter_arr = this.col_show[this.title]
        this.selectedValue = this.filter_arr[0]
      }

      if (this.paginationList.indexOf(this.title) > -1) {
        this.showPagination = false
      }
      if (response["viz_type"] == "pivot_table") {
        let PivotData = this.ptot.pivotToTable(response)
        this.displayedColumns = PivotData.tableHeadersMerged

        // # To hide given columns of only pivot table response
        this.displayedColumns = this.displayedColumns.filter(
          x => !this.hide_colms.includes(x)
        );

        tableRows = PivotData.tableRowMerged
      } else {
        if (response["data"]["data"]["records"]) {
          this.second = ["a", "b"]
          this.displayedColumns = response["data"]["data"]["columns"]
          for (var i = 0; i < this.displayedColumns.length; i++) {
            if (this.displayedColumns[i] === 'account_id') {
              // # Adding call button when account id column occurs
              this.displayedColumns.push("Contact")
            }
            if (this.headers[this.displayedColumns[i]]) {
              this.displayedColumns[i] = this.headers[this.displayedColumns[i]]
            }
          }

          // To hide given columns of non pivot table response
          this.displayedColumns = this.displayedColumns.filter(
            x => !this.hide_colms.includes(x)
          );

          let tableData = []
          for (let data in response["data"]["data"]["records"]) {
            let tableDataIndex = response["data"]["data"]["records"][data]
            tableData[data] = {}
            for (let keys in tableDataIndex) {
              if (this.headers[keys]) {
                tableData[data][this.headers[keys]] = tableDataIndex[keys]
              } else {
                tableData[data][keys] = tableDataIndex[keys]
              }
            }
          }

          tableRows = tableData
        }
      }
      if (response["data"]["data"]["columns"]) {
        if (Object.values(response["data"]["data"]["columns"]).includes('product_type')) {
          this.product = response["data"]["data"]["records"][0].product_type

        }
      }
      if (tableRows) {
        this.report_data = true
        let user_token = localStorage.getItem('ta_token')
        for (i = 0; i < tableRows.length; i++) {
          tableRows[i]["Contact"] = this.domSanitizer.bypassSecurityTrustHtml('<div style="cursor: pointer;" onclick="dialer(event)" id=' + tableRows[i]["User Id"] + '><img  img width="32" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Antu_call-start.svg/32px-Antu_call-start.svg.png"></div>')
          if (tableRows[i]["User Id"] && tableRows[i]["User Id"].includes('EI')) {
            tableRows[i]["Contact"] = this.domSanitizer.bypassSecurityTrustHtml('<div style="cursor: pointer;" onclick="dialer(event)" id=' + tableRows[i]['account_id'] + '><img  img width="32" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Antu_call-start.svg/32px-Antu_call-start.svg.png"></div>')
          }
          if (response["meta"]["drillable"]) {
            if (tableRows[i]["User Id"]) {
              if (tableRows[i]["User Id"].includes('EI') && response["meta"]["executive_report_id"]) {
                // let link = this.config.get("baseUrl") + '/reports/' + response["meta"]["executive_report_id"] + '/' + tableRows[i]['account_id'] + '?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
                let link = this.baseUrl + '/reports/' + response["meta"]["executive_report_id"] + '/' + tableRows[i]['account_id'] + '?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
                tableRows[i]["Name"] = '<a href="' + link + '">' + tableRows[i]["Name"] + '</a>'
                tableRows[i]["User Id"] = '<a href="' + link + '">' + tableRows[i]["User Id"] + '</a>'
              }
              else if (tableRows[i]["User Id"].includes('EI') && !response["meta"]["executive_report_id"] && this.role !== "204") {
                // let link = this.config.get("rb_session_url") + '/sales/partner/' + tableRows[i]["User Id"] + '/summary-iframe/?source=dialer'
                let link = environment.rb_session_url + '/sales/partner/' + tableRows[i]["User Id"] + '/summary-iframe/?source=dialer'
                tableRows[i]["Name"] = this.domSanitizer.bypassSecurityTrustHtml('<form action="' + link + '" method="post" target="_blank"><button type="submit" name="token" value="' + user_token + '" class="btts">' + tableRows[i]["Name"] + '</button></form>')
                tableRows[i]["User Id"] = this.domSanitizer.bypassSecurityTrustHtml('<form action="' + link + '" method="post" target="_blank"><button type="submit" name="token" value="' + user_token + '" class="btts">' + tableRows[i]["User Id"] + '</button></form>')
              } else {
                if (response["meta"]["next_report_id"] && !tableRows[i]["User Id"].includes('EI')) {
                  let list_data = [tableRows[i]["User Id"], tableRows[i]["Name"].replace(/ /g, '_')]
                  // let link = this.config.get("baseUrl") + '/reports/' + response["meta"]["next_report_id"] + '/' + tableRows[i]['account_id'] + '?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
                  let link = this.baseUrl + '/reports/' + response["meta"]["next_report_id"] + '/' + tableRows[i]['account_id'] + '?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
                  tableRows[i]["Name"] = this.domSanitizer.bypassSecurityTrustHtml('<a href="' + link + '" onclick="UserNameFunction(event)" id=' + list_data + '>' + tableRows[i]["Name"] + '</a>')
                  tableRows[i]["User Id"] = this.domSanitizer.bypassSecurityTrustHtml('<a href="' + link + '" onclick="UserNameFunction(event)" id=' + list_data + '>' + tableRows[i]["User Id"] + '</a>')
                }
              }
            } else {
              // let link = this.config.get("baseUrl") + '/reports/' + response["meta"]["next_report_id"] + '/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
              let link = this.baseUrl + '/reports/' + response["meta"]["next_report_id"] + '/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id
              tableRows[i][Object.keys(tableRows[i])[0]] = '<a href="' + link + '">' + tableRows[i][Object.keys(tableRows[i])[0]] + '</a>'
            }
          } else {
            if (tableRows[i]["User Id"] && tableRows[i]["User Id"].includes('EI') && this.role !== "204") {
              // let link = this.config.get("rb_session_url") + '/sales/partner/' + tableRows[i]["User Id"] + '/summary-iframe/?source=dialer'
              let link = environment.rb_session_url + '/sales/partner/' + tableRows[i]["User Id"] + '/summary-iframe/?source=dialer'
              tableRows[i]["Name"] = this.domSanitizer.bypassSecurityTrustHtml('<form action="' + link + '" method="post" target="_blank"><button type="submit" name="token" value="' + user_token + '" class="btts">' + tableRows[i]["Name"] + '</button></form>')
              tableRows[i]["User Id"] = this.domSanitizer.bypassSecurityTrustHtml('<form action="' + link + '" method="post" target="_blank"><button type="submit" name="token" value="' + user_token + '" class="btts">' + tableRows[i]["User Id"] + '</button></form>')
            }
          }
          tableRows[i]["Product"] = this.headers[tableRows[i]["Product"]]
          let data = [tableRows[i]['Expiring_Policy_No'], this.product]
          tableRows[i]["Renewal Notice"] = this.domSanitizer.bypassSecurityTrustHtml('<div class="send_button" onclick="get_renewal_doc_link(event)" id=' + data + '>' + 'Download' + '</div>')
          if (tableRows[i]["One Click Url"]) {
            let data = [tableRows[i]['payment_id'], tableRows[i]['product_type']]
            // tableRows[i]["One Click Url"] = '<a href="' + this.config.get("backend") + tableRows[i]["One Click Url"] + '" target="_blank" class="send_button">' + 'Pay Now' + '</a>'
            tableRows[i]["One Click Url"] = '<a href="' + environment.backend + tableRows[i]["One Click Url"] + '" target="_blank" class="send_button">' + 'Pay Now' + '</a>'
            tableRows[i]["Renewal Notice"] = this.domSanitizer.bypassSecurityTrustHtml('<div class="send_button" onclick="sendNotice(event)" id=' + data + '>' + 'Send Notice' + '</div>')
          }
          let wp_data = [tableRows[i]['Expiring_Policy_No'], this.product]
          tableRows[i]["Renewal Link"] = this.domSanitizer.bypassSecurityTrustHtml('<div class="send_button" onclick="send_renewal_link(event)" id=' + wp_data + '>' + 'Send Link' + '</div>')
          let salesData = [tableRows[i]['account_id']]
          if (tableRows[i]["view"]) {
            tableRows[i]["view"] = this.domSanitizer.bypassSecurityTrustHtml('<div class="send_button" onclick="redirect_sales_promotion(event)" id=' + salesData + '>' + 'View' + '</div>')
          }
          else {
            tableRows[i]["view"] = "<b><font color='blue'>Click on Name to Drill Down</font></b>"
          }

          for (var j = 0; j < this.showRs.length; j++) {
            let visibleRs = this.showRs[j];
            tableRows[i][visibleRs] = this.toInr(tableRows[i][visibleRs]);
          }
          for (var k = 0; k < this.showArr.length; k++) {
            let visibleArr = this.showArr[k];
            tableRows[i][visibleArr] = this.toArrow(tableRows[i][visibleArr]);
          }

          for (var l = 0; l < this.showGrowth.length; l++) {
            let visibleArr = this.showGrowth[l];
            tableRows[i][visibleArr] = this.toGrowth(tableRows[i][visibleArr]);
          }
        }
        this.response = new MatTableDataSource(tableRows)
        this.datasource = new MatTableDataSource(tableRows)
        setTimeout(() => {
          this.response.paginator = this.paginator
          this.response.sort = this.sort
          this.response.sortingDataAccessor = (item, property) => {
            if (property == "Total Premium" || property == "Weightage Premium") {
              let amount = item[property]
              // let prefix = amount.charAt(amount.length - 1);
              let prefix = amount.split(" ")[2]
              amount = amount.replace(/₹ /g, '').replace(/\D/g, '')
              amount = parseInt(amount)
              if (prefix == 'k') {
                amount = amount * 1000
              } else if (prefix == 'lac') {
                amount = amount * 100000
              } else if (prefix == 'cr') {
                amount = amount * 10000000
              }
              return amount
            } else {
              return item[property]
            }
          }
        })
      }
    }
  }

  send_report() {
    if (this.data['data']['data']) {
      let values = {
        'data_records': this.data,
        'end_date': this.api.getDate(this.end_date),
        'start_date': this.api.getDate(this.start_date),
        'account_id': localStorage.getItem('code')
      }
      let title = 'Report will be sent on your email shortly'
      this.api.sendReport(values).then(response => {
        if (response['error']) {
          title = response['error']
        }
        if (this.inTheZone) {
          this.inTheZone = false
          this.ngZone.run(() => {
            let dialogRef = this.dialog.open(DialogComponent, {
              width: '350px',
              data: {
                title: title,
                callback: () => { this.inTheZone = true }
              }
            });
          })
        }
      });
    }
  }

  UserNameFunction = event => {
    var val_array = event.target.id.split(',');
    let context = {
      "user_name": val_array[1].replace(/_/g, ' '),
      "account_id": val_array[0]
    }
    let breadcrumbs = JSON.parse(localStorage.getItem("breadcrums"))
    breadcrumbs.push(context)
    localStorage.setItem("breadcrums", JSON.stringify(breadcrumbs))
  }
  get_renewal_doc_link = (event) => {
    let data = event.currentTarget.id
    let policy_no = data.toString().split(',')[0]
    let p_type = data.toString().split(',')[1]

    this.api.getRenewalNotice(policy_no, p_type).then(response => {
      window.open(response.toString())
    })
  }

  send_renewal_link = (event) => {
    let data = event.currentTarget.id
    let policy_no = data.toString().split(',')[0]
    let p_type = data.toString().split(',')[1]

    this.api.sendRenewalLinkWhatsapp(policy_no, p_type).then(response => {
      let title = 'Error renewal link not exists in system !!'
      if (response['url']) {
        title = response['msg']
      }
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              callback: () => { this.inTheZone = true }
            }
          });
        })
      }
    })
  }

  sendNotice = event => {
    let data = { 'values': event.currentTarget.id }
    this.api.noticeEmailApi(data).then(response => {
      let title = 'Error renewal payment not exists in system !!'
      if (response['response'] == 'success') {
        title = 'Email is sent to resepective partner and customer Successfully'
      }
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              callback: () => { this.inTheZone = true }
            }
          });
        })
      }
    })
  }

  downloadFile(data: any[], filename: string): void {
    const csv = Papa.unparse({ fields: data["columns"], data: data["records"] });
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a link element, set its attributes, and trigger a click event
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    link.click();
  }

  download_report(): void {
    const data = this.data["data"]["data"]
    if (data) {
      const filename = this.title;
      this.downloadFile(data, filename);
    } else {
      let title = 'No Data Found !!'
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              callback: () => { this.inTheZone = true }
            }
          });
        })
      }
    }
  }

  dialer = event => {
    var partner_code = event.currentTarget.id;
    let data = {
      reportee: partner_code,
      is_dialer: true
    }
    this.api.getDailerInfo(data).then(response => {
      let title: string = 'Success';
      let msg = response;
      let msg_key = "Mobile Number"
      if (this.screenWidth <= 767 && title == 'Success') {
        let aTag = document.createElement('a');
        let tellHref = 'tel:' + response
        aTag.setAttribute('href', tellHref);
        aTag.innerText = `${response}`;
        msg = aTag;
      }
      if (!response) {
        title = "Error"
        msg = "You are not authorized to access this mobile number"
        msg_key = "Error"
      }
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              msg: msg,
              msg_key: msg_key,
              callback: () => { this.inTheZone = true }
            }
          });
        })
      }


    })


  }
  windowFunction() {
    window['dialer'] = this.dialer
    window['sendNotice'] = this.sendNotice
    window['UserNameFunction'] = this.UserNameFunction
    window['get_renewal_doc_link'] = this.get_renewal_doc_link
    window['redirect_sales_promotion'] = this.redirect_sales_promotion
    window['send_renewal_link'] = this.send_renewal_link
  }
  toInr(amount) {
    let filtered_amount = ''
    if (isNaN(amount)) {
      filtered_amount = amount
    } else {
      if (Math.abs(amount) > 9999999) {
        filtered_amount = (Math.abs(amount) / 10000000).toFixed(2) + ' cr'
      } else if (Math.abs(amount) > 99999) {
        filtered_amount = (Math.abs(amount) / 100000).toFixed(2) + ' lac'
      } else if (Math.abs(amount) > 999) {
        filtered_amount = (Math.abs(amount) / 1000).toFixed(2) + ' k'
      } else {
        filtered_amount = (Math.sign(amount) * Math.abs(amount)) + ''
      }
    }

    return `₹ ${filtered_amount}`
  }

  toArrow(countGrowth) {
    let filtered_growth = ""
    filtered_growth = countGrowth;
    if (countGrowth == 0) {
      return this.domSanitizer.bypassSecurityTrustHtml('<span class="percent_width">' + `${filtered_growth}` + '%</span> <img width="20" alt="Square Darker" src="https://upload.wikimedia.org/wikipedia/commons/0/09/Solid_yellow.svg">')
    } else if (countGrowth > 0) {
      return this.domSanitizer.bypassSecurityTrustHtml('<span class="percent_width">' + `${filtered_growth}` + '%</span> <img width="20" alt="Green Arrow Up Darker" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Green_Arrow_Up_Darker.svg/32px-Green_Arrow_Up_Darker.svg.png">')
    } else {
      return this.domSanitizer.bypassSecurityTrustHtml('<span class="percent_width">' + `${filtered_growth}` + '%</span> <img width="20" alt="Red Arrow Down Darker" src="https://upload.wikimedia.org/wikipedia/commons/0/04/Red_Arrow_Down.svg">')
    }
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  toGrowth(growth) {
    let filtered_growth = ""
    filtered_growth = growth;
    if (growth) {
      return this.domSanitizer.bypassSecurityTrustHtml('<span class="percent_width">' + `${filtered_growth}` + '%</span> ')
    }
  }

  ngOnInit() {
    this.allCols = this.displayedColumns
    if (this.selectedValue) {
      this.dropDownFilter(this.selectedValue)
    }
  }
}