import { Component, OnInit, Input, ViewChild, NgZone, HostListener, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';


@Component({
  selector: 'app-ifls-life-renewal',
  templateUrl: './ifls-life-renewal.component.html',
  styleUrls: ['./ifls-life-renewal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IflsLifeRenewalComponent implements OnInit {

    public loading: Boolean = true;
    public title = 'Life Renewals Report';
    displayedColumns: string[] = ['proposal__partner__code', 'due_renewals_nop', 'due_renewals_premium'];
    response: any;
    @Input() showPicker = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public showPagination = true;
    public selected_date: any;
    public sortResponse: any;
    public paginationList: Array<any> = []
    public dateFlag : Boolean = false;
    public screenWidth : any;
    public inTheZone : Boolean = true;
    public reportingManagers: any = [];
    public reportingHierarchy: any;
    public user_code: any;
    public date: Date = new Date()
    public start_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
    public end_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
    applyFilter(filterValue: string) {
        this.sortResponse.filter = filterValue.trim().toLowerCase();
    }

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private config: ConfigService,
    private auth: AuthService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {

    this.activatedRoute.queryParams.subscribe(params => {

      if (params['start_date'] && params['end_date']) {
        this.start_date = params['start_date']
        this.end_date = params['end_date']
      }
    });

    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        
        this.start_date = this.datePipe.transform(this.start_date, 'yyyy-MM-dd')
        this.end_date = this.datePipe.transform(this.end_date, 'yyyy-MM-dd')
        var data = {
          "start_date": this.start_date,
          "end_date": this.end_date,
        }
        
        this.api.getRenewalsDueData(data).then((response) => {
          this.loading = true;
          this.response = response;          
          if (this.response.length > 0) {
            this.loading = false;
            const tableData = [];
            let tableRows;
            let tableDataIndex;
            if (this.response) {
              
              tableDataIndex = this.response;
              tableDataIndex = tableDataIndex.sort((a,b) => b.due_renewals_nop - a.due_renewals_nop);
              for (let i = 0; i < tableDataIndex.length; i++) {
                tableDataIndex[i]['due_renewals_premium'] = this.toInr(tableDataIndex[i]['due_renewals_premium'])
                tableData[i] = {};
                tableData[i] = tableDataIndex[i];
                
              }
            }

            tableRows = tableData;
            
            
            this.sortResponse = new MatTableDataSource(tableRows);
            setTimeout(() => {
              this.sortResponse.paginator = this.paginator;
              this.sortResponse.sort = this.sort;
            })
            
            if (this.paginationList.indexOf(this.sortResponse) > -1) {
              this.showPagination = false;
            }
            
          }else {
            this.loading = false;
          }
        });
      }
    });

  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  dialer(ev: any) {
    var partner_code = ev.proposal__partner__code;
    let data = {
      reportee: partner_code,
      is_dialer: true
    }
    this.api.getDailerInfo(data).then(response => {
      let title: string = 'Success';
      let msg = response;
      let msg_key = "Mobile Number"
      if (this.screenWidth <= 767 && title == 'Success') {
        let aTag = document.createElement('a');
        let tellHref = 'tel:' + response
        aTag.setAttribute('href', tellHref);
        aTag.innerText = `${response}`;
        msg = aTag;
      }
      if (!response) {
        title = "Error"
        msg = "You are not authorized to access this mobile number"
        msg_key = "Error"
      }
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              msg: msg,
              msg_key: msg_key,
              callback: () => { this.inTheZone = true }
            }
          })
        })
      }
    })
  }

  toInr(amount) {
    let filtered_amount = ''
    if (isNaN(amount)) {
      filtered_amount = amount
    } else {
      if (Math.abs(amount) > 9999999) {
        filtered_amount = (Math.abs(amount) / 10000000).toFixed(2) + ' cr'
      } else if (Math.abs(amount) > 99999) {
        filtered_amount = (Math.abs(amount) / 100000).toFixed(2) + ' lac'
      } else if (Math.abs(amount) > 999) {
        filtered_amount = (Math.abs(amount) / 1000).toFixed(2) + ' k'
      } else {
        filtered_amount = (Math.sign(amount) * Math.abs(amount)) + ''
      }
    }

    return `₹ ${filtered_amount}`
  }


  ngOnInit() {
  }

}
