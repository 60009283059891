import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
// import { ConfigService } from 'src/app/config/config.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit {

  public date: Date = new Date()
  public start_date: string = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
  public end_date: string = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
  public product_type_id;
  public Products: Array<any> = [];
  public selected: Array<any> = ['Select Products'];
  public allProducts: boolean
  public product_value: any[];

  onChange(name: string, product_type: Array<any>, isChecked: boolean) {

    if (this.product_type_id) {
      if (isChecked) {
        this.product_type_id = this.product_type_id.concat(product_type);
        this.selected = this.selected.concat(name).filter(e => e !== 'Select Products');
      } else {
        this.product_type_id = this.product_type_id.filter(e => e != product_type)
        this.selected = this.selected.filter(n => !name.includes(n))
        this.allProducts = false
      }
    }
    else {
      this.product_type_id = product_type
      this.selected = this.selected.concat(name).filter(e => e !== 'Select Products');
    }
  }

  checkUncheck(event) {

    const checked = event.target.checked;
    this.product_type_id = []
    if (checked == true) {
      for (let i = 0; i < this.Products.length; i++) {
        this.product_type_id = this.product_type_id.concat(this.Products[i].product_type)
        this.selected = this.selected.concat(this.Products[i].name).filter(e => e !== 'Select Products');
        this.Products[i].isSelected = checked
        this.allProducts = checked
      }
    }
    else {
      this.Products.forEach(item => item.isSelected = checked);
      this.product_type_id = []
      this.selected = ['Select Products']
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: Http,
    // private config: ConfigService,
    private api: ApiService,
    private auth: AuthService,
  ) {

    // this.Products = this.config.getText("Products")
    this.Products = environment.Products

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['end_date']) {
        this.start_date = params['start_date']
        this.end_date = params['end_date']
      }
      this.product_type_id = params['product_type_id']

    });
  }

  ngOnInit() {
    // Identifying which one is checked
    let result;
    let count = 0;
    if (this.product_type_id) {
      this.product_type_id = this.product_type_id.split(',')
      for (let i = 0; i < this.Products.length; i++) {
        result = this.product_type_id.includes(this.Products[i].product_type)
        if (result) {
          this.Products[i].isSelected = result
          this.selected = this.selected.concat(this.Products[i].name).filter(e => e !== 'Select Products');
          count++;
        }
      }
      this.product_type_id = this.Products.reduce((a, o) => (o.isSelected && a.push(o.product_type), a), []);
    }
    if (count == this.Products.length) {
      this.allProducts = true
      this.selected = ['All Products Selected']
    }
    else {
      this.allProducts = false
    }
  }
}
