import { Component, ViewEncapsulation } from '@angular/core';
import { SidebarService } from './components/sidebar/sidebar.services';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'dossier';
  public toggle;
  public role;

  constructor(private sidebar: SidebarService, private auth: AuthService, private api: ApiService) {
    this.sidebar.showNav.subscribe((navStatus) => {
      this.toggle = navStatus
    })
    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.api.getSideMenu().then((response) => {
          this.role = response['user_details']['emp_role']
        })
      }
    })
  }
}
