import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { WindowRef } from '../../services/window.service';
import { SidebarService } from '../sidebar/sidebar.services';
import { ApiService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() role: any;
  elem = null;
  isLoggedIn$: Observable<boolean>;
  amsurl = '';
  activeEtlModal: boolean = false
  i_meet_btn_var: boolean = false
  insurance_toggle: boolean = false
  paymaster_toggle: boolean = false;
  isAllowed: boolean = false
  public toggle: any = false
  public sales;
  public book_appointment_link1;
  public book_appointment_link2;
  public user_token: string;
  public user_id: any;
  public etlJobsList: any;
  public paymaster_url;
  public rb_suraksha_finsure;
  public redirectionList: any;
  public dossier_redirection: boolean = true;
  public paymaster_special_url;
  public paymasterSpecialList: any;
  // public beta_check: boolean = false;

  constructor(
    private auth: AuthService,
    // private config: ConfigService,
    public win: WindowRef,
    private route: ActivatedRoute,
    private router: Router,
    private sidebar: SidebarService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {
    this.sidebar.showNav.subscribe((navStatus) => {
      this.toggle = navStatus
    })
    // this.amsurl = this.config.get('amsURL')
    this.amsurl = environment.amsURL
    // this.sales = this.config.get('rb_session_url') + '/sales/'
    this.sales = environment.rb_session_url + '/sales/'
    // this.book_appointment_link1 = this.config.get('book_appointment_link1')
    this.book_appointment_link1 = environment.book_appointment_link1
    // this.book_appointment_link2 = this.config.get('book_appointment_link2')
    this.book_appointment_link2 = environment.book_appointment_link2
    // this.paymaster_url = this.config.get('paymaster_redirection_url')
    this.paymaster_url = environment.paymaster_redirection_url
    // this.rb_suraksha_finsure = this.config.get("rb_life_url")
    this.paymaster_special_url = environment.paymaster_special_url
    this.rb_suraksha_finsure = environment.rb_life_url
    // this.redirectionList = this.config.getText("redirectionList")
    this.redirectionList = environment.redirectionList
    // this.beta_check = this.auth.GetBetaFeature()
    this.paymasterSpecialList = environment.paymaster_special_redirections
  }

  logout() {
    this.auth.logOut()
  }

  ngAfterViewInit() {
    // this.loadScript(this.config.get('amsURL') + 'static/js/loginWidget.js')
    this.loadScript(this.amsurl + 'static/js/loginWidget.js')
  }

  loadScript(url) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  @ViewChild('widgetId') widgetId: ElementRef;
  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn;

    this.elem = this.widgetId.nativeElement;
    new this.win.nativeWindow.RB_AMS_SDK({
      'userInfo': this.elem,
      'islogIn': this.auth.setUser,
      'UserlogOut': this.auth.logOut,
      // "amsurl": this.config.get('amsURL'),
      "amsurl": this.amsurl,
      'loginBoxStyle1': true,
      'widgetFont': '14px'
    })

    setTimeout(() => {
      this.user_token = localStorage.getItem('ta_token');
      this.user_id = localStorage.getItem("code");
    }, 500);
  }

  toggleEtlModal() {
    this.activeEtlModal = !this.activeEtlModal;
  }

  getDate(date) {
    if (!date) return '-'
    return this.datePipe.transform(date, 'yyyy-MM-dd') + ' ' + this.getTime(date)
  }

  getTime(date) {
    if (!date) return '-'
    let timeString = this.datePipe.transform(date, 'HH:mm:ss')
    return this.convertTimeToAMPM(timeString)
  }

  convertTimeToAMPM(timeString: string): string {
    const [hour, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hour));
    date.setMinutes(parseInt(minutes));

    let formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const suffix = date.getHours() >= 12 ? 'PM' : 'AM';

    formattedTime = formattedTime.replace(/(:\d{2})$/, `$1 ${suffix}`);

    return formattedTime;
  }

  fixDate(data: any) {

    for (let i = 0; i < data.length; i++) {
      data[i]['last_successful_run_at'] = this.getDate(data[i]['last_successful_run_at'])
      data[i]['last_transaction_updated_at'] = this.getDate(data[i]['last_transaction_updated_at'])
      data[i]['last_transaction_created_at'] = this.getDate(data[i]['last_transaction_created_at'])
    }
    return data
  }

  getEtlData() {
    this.api.getEtlData().then((result) => {
      if (result) {
        this.etlJobsList = this.fixDate(result)
      }
    })
  }

  getEtlJobsData() {
    if (!this.activeEtlModal) {
      this.getEtlData();
    }
    this.toggleEtlModal();
  }

  redirectToPaymaster() {
    window.open(this.paymaster_url)
  }

  onNavItemClick(id) {
    this.router.navigate([`/home/${id}`])
  }


  toggleNav() {
    this.toggle = !this.toggle
    this.sidebar.toggleNav(this.toggle)
  }

  insuranceToggle() {
    if (this.i_meet_btn_var) {
      this.i_meet_btn_var = !this.i_meet_btn_var
    }
    if (this.paymaster_toggle) {
      this.paymaster_toggle = !this.paymaster_toggle
    }
    this.insurance_toggle = !this.insurance_toggle
  }

  iMeetToggle() {
    if (this.insurance_toggle) {
      this.insurance_toggle = !this.insurance_toggle
    }
    if (this.paymaster_toggle) {
      this.paymaster_toggle = !this.paymaster_toggle
    }
    this.i_meet_btn_var = !this.i_meet_btn_var
  }

  togglePaymaster() {
    if (this.i_meet_btn_var) {
      this.i_meet_btn_var = !this.i_meet_btn_var
    }
    if (this.insurance_toggle) {
      this.insurance_toggle = !this.insurance_toggle
    }
    if (this.role == 201) {
      this.isAllowed = true
    }
    this.paymaster_toggle = !this.paymaster_toggle
  }


}
