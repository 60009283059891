import { Component, OnInit, Input, ViewChild, NgZone, HostListener, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mapped-partners',
  templateUrl: './mapped-partners.component.html',
  styleUrls: ['./mapped-partners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MappedPartnersComponent implements OnInit {

  public loading: Boolean = true;
    public title = 'Mapped Partners';
    displayedColumns: string[] = ['name', 'pos_code', 'life_pos','mapped_rb_fls', 'contact'];
    response: any;
    @Input() showPicker = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public showPagination = true;
    public selected_date: any;
    public sortResponse: any;
    public paginationList: Array<any> = []
    public date : any;
    public dateFlag : Boolean = false;
    public screenWidth : any;
    public inTheZone : Boolean = true;
    public reportingManagers: any = [];
    public reportingHierarchy: any;
    applyFilter(filterValue: string) {
        this.sortResponse.filter = filterValue.trim().toLowerCase();
    }

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private config: ConfigService,
    private auth: AuthService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {

    // this.reportingHierarchy = this.config.getText('reporting_types')
    this.reportingHierarchy = environment.reporting_types
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.selected_date) {
        this.selected_date = new Date(params.selected_date);
        this.dateFlag = true;
      }
    });

    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if (this.dateFlag) {
          this.date = this.datePipe.transform(this.selected_date, 'dd/MM/yyyy')
          this.dateFlag = false
        }
        else {
          this.date = this.datePipe.transform(new Date(), 'dd/MM/yyyy')
        }
        var data = {
          "as_on": this.date,
          "return_type": "pos_code"
        }
        this.api.getMappedPartners(data).then((response) => {
          this.loading = true;
          this.response = response;
          if (this.response.length > 0) {

            this.loading = false;


            const tableData = [];
            let tableRows;
            let tableDataIndex;
            if (this.response) {
              tableDataIndex = this.response;
              for (let i = 0; i < tableDataIndex.length; i++) {

                tableData[i] = {};
                tableData[i] = tableDataIndex[i];

              }
            }

            tableRows = tableData;

            this.sortResponse = new MatTableDataSource(tableRows);
            setTimeout(() => {
              this.sortResponse.paginator = this.paginator;
              this.sortResponse.sort = this.sort;
            })

            if (this.paginationList.indexOf(this.sortResponse) > -1) {
              this.showPagination = false;
            }

          }

        });
      }
    });

  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  dialer(ev: any) {
    var partner_code = ev.unique_code;
    let data = {
      reportee: partner_code,
      is_dialer: true
    }
    this.api.getDailerInfo(data).then(response => {
      let title: string = 'Success';
      let msg = response;
      let msg_key = "Mobile Number"
      if (this.screenWidth <= 767 && title == 'Success') {
        let aTag = document.createElement('a');
        let tellHref = 'tel:' + response
        aTag.setAttribute('href', tellHref);
        aTag.innerText = `${response}`;
        msg = aTag;
      }
      if (!response) {
        title = "Error"
        msg = "You are not authorized to access this mobile number"
        msg_key = "Error"
      }
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
              title: title,
              msg: msg,
              msg_key: msg_key,
              callback: () => { this.inTheZone = true }
            }
          })
        })
      }
    })
  }

  getReportingManagers = (element: any) => {
    let account_id = element['unique_code']
    let data = {
      "user_id": account_id
    }
    this.api.getReportingManagers(data).then(response => {
      this.reportingManagers = response
      let data = []
      for(let i of this.reportingManagers){
        if(i['reporting_type'] in this.reportingHierarchy){
          i['reporting_name'] = this.reportingHierarchy[i['reporting_type']]
          data.push(i)
        }
      }
      let msg = {"reporting":data}
      let msg_key = "RM Details"
      let title = "Partner Reporting"
      let data_key = "reporting"
      
      if (this.inTheZone) {
        this.inTheZone = false
        this.ngZone.run(() => {
          let dialogRef = this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
              title: title,
              msg: msg,
              msg_key: msg_key,
              data_key: data_key,
              callback: () => { this.inTheZone = true }
            }
          })
        })
      }
    })
  }


  ngOnInit() {
  }

}
