import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { ConfigService } from '../../config/config.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { SidebarService } from './sidebar.services';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public navItem: any
  public url: string
  public id: any
  public base: string
  public toggle
  public salesurl
  public reset_month_filter: any
  public product_type_id: any
  public date: Date = new Date()
  public start_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
  public end_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
  public role: any

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private config: ConfigService,
    private auth: AuthService,
    private sidebar: SidebarService,
    private location: Location

  ) {
    // this.sidebar.showNav.subscribe((navStatus) => {
    //   this.toggle = navStatus
    // })

    // this.reset_month_filter = this.config.getText("reset_month_filter")
    this.reset_month_filter = environment.reset_month_filter

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['end_date']) {
        this.url = "?start_date=" + params['start_date'] + "&end_date=" + params['end_date']
      }
      if (params['product_type_id']) {
        this.product_type_id = params['product_type_id']
        this.url = "?start_date=" + params['start_date'] + "&end_date=" + params['end_date'] + "&product_type_id=" + params['product_type_id']
      }
    });
    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.api.getSideMenu().then((response) => {
          this.id = window.location.pathname
          this.navItem = response['dashboards']
          this.role = response['user_details']['emp_role']
          // this.base = this.config.get("baseUrl")
          this.base = environment.baseUrl
          // this.salesurl = this.config.get("salesPortal")
          this.salesurl = environment.salesPortal
        })
      }
    })
  }

  /**
   * Using location to idedntify xurrent addeess and route to its specific
   */

  goToManageteam(routeObtained: string) {

    if (routeObtained) {
      this.router.navigate([routeObtained]);
    } else {
      this.router.navigate([routeObtained]);
    }

  }

  // Change dates of url
  changeUrl(dashboard_name: any) {
    if (this.reset_month_filter.includes(dashboard_name) && this.url) {

      this.url = "?start_date=" + this.start_date + "&end_date=" + this.end_date
      if (this.product_type_id) {
        this.url = "?start_date=" + this.start_date + "&end_date=" + this.end_date + "&product_type_id=" + this.product_type_id
      }
    }
  }

  ngOnInit() {
  }

}
