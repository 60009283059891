// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  advisorFinderProduct: {
    1: "Motor Query",
    2: "Motor Query",
    3: "Health Query",
    4: "Life Query",
    5: "Motor Query",
  },
  "api": "/api/",
  "apiv2": "/api/v2/",
  "secret-key": "gCqOWGnAoy8R8SWnmj08xE0OkmOFEiLf",
  "api-key": "9893d9c3-54fe-480d-a172-862c77100cb7",
  "glue-secret-key": "dCr8hEr8WWaSlxoNrh6Dst9PLzWQJnqD",
  "glue-api-key": "945dbc41-8587-4c62-82ca-741f914077cf",
  "apiSecretKeyCO": "K0hMXX8dqyr4NUIRsmnZPjnYBiH9ttYz",
  "baseUrlCO": "http://13.127.84.36",
  "runPlatformCo": "dev",
  "appIdCO": "e0b9f629-cae2-444a-b194-6f8a1fa39829",
  "razorPayKey": "rzp_test_VYg6F7Z7oQdxNZ",
  "resetpassword": "http://uat.travassured.com/password_reset/",
  "amsURL": "https://accounts.rbstaging.in/",
  "dossierURL": "https://dossier.rbstaging.in/",
  "baseUrl": "https://dossier.rbstaging.in/",
  "salesPortal": "https://dev.renewbuy.com/sales/partner/search/",
  "sales": "https://dev.renewbuy.com/sales/",
  "backend": "https://dev.renewbuy.com/",
  "book_appointment_link1": "http://meetings.rbstaging.in/",
  "book_appointment_link2": "http://meetings.rbstaging.in/",
  "rb_session_url": "https://dev.renewbuy.com/accounts/create_session/?next=",
  "salesPromotionUrl": "https://insurerdashboard.rbstaging.in/#/sales",
  "paymaster_redirection_url": "https://paymasterv2.rbstaging.in/rule-search",
  "rb_life_url": "https://rbfinance.rbstaging.in/api_life/v1/life_validation/validate_token/",
  "paymaster_special_url": "https://paymasterv2.rbstaging.in/rules/special/motor4W",
  "tableHeader": {
    "unique_code": "User Id",
    "SUM(paid_amount)": "Total Premium",
    "SUM(weightage_premium)": "Weightage Premium",
    "COUNT(id)": "Policies count",
    "transaction_identifier": "Payment Id",
    "executive_name": "Partner Name",
    "executive_pos_code": "Partner Code",
    "count": "Policies count",
    "premium_payment_term": "Premium Payment Term",
    "premium_installment_type": "Premium Installment Type",
    "user_name": "Name",
    "product_type_id": "Product",
    "renewal_doc_link": "Renewal Notice",
    "renewal_notice": "Renewal Notice",
    "renewal_link": "Renewal Link",
    "one_click_url": "One Click Url",
    "total_partners": "Total Partners",
    "team_size": "Team Size",
    "doc_submission_date": "Document Submission Date",
    "li_training_start_date": "LI Traning Start Date",
    "li_training_completed_date": "LI Traning Complete Date",
    "gi_training_start_date": "GI Traning Start Date",
    "gi_training_completed_date": "GI Traning Complete Date",
    "doc_verified": "Document Verified",
    "li_pos_date": "LI Pos Date",
    "is_li_pos_certified": "LI Pos Certified",
    "gi_pos_date": "GI Pos Date",
    "is_gi_pos_certified": "GI Pos Certified",
    "All": "Total",
    "9": "Two Wheeler Comprehensive",
    "10": "Four Wheeler Comprehensive",
    "11": "Health Topup",
    "12": "Health Base",
    "21": "RPA",
    "22": "Commercial(PCV)",
    "23": "Commercial(GCV)",
    "24": "Commercial(MISC)",
    "25": "TravAssured",
    "26": "HealthAssure",
    "27": "TravAssured w/o SIM",
    "28": "TwoWheelerFP",
    "29": "Two Wheeler Third Party",
    "30": "Four Wheeler Third Party",
    "31": "Commercial(PCV) Third Party",
    "32": "Commercial(GCV) Third Party",
    "33": "Commercial(MISC) Third Party",
    "34": "Life Protection",
    "35": "Life Saving",
    "36": "Life Trop"
  },
  "contentText": {},
  "table_view": {
    "Health business": "col-md-6",
    "Channel success summary": "col-md-6",
    "Channel cancellation summary": "col-md-6",
    "Inwared summary product wise": "col-md-6",
    "Success summary product wise": "col-md-6",
    "Pending summary product wise": "col-md-6",
    "Cancelled summary product wise": "col-md-6",
    "Team report success summary": "col-md-6",
    "Team report cancelled summary": "col-md-6"
  },
  "fixedDateDashboard": {},
  "foot_note": {
    "User insurer wise business": "Data will be refreshed every 5 minutes.",
    "Team business": "Content to be shown in footer of Team business",
    "Transactions": "Content to be shown in footer of Transactions",
    "Bqp transactions": "Content to be shown in footer of Bqp transactions"
  },
  "hidePagination": [],
  "hideProductType": [
    "Direct dashboard",
    "North & west zone contest",
    "South zone contest",
    "East zone contest",
    "Partner activation dashboard revist",
    "Life issuance channel report",
    "Life issuance team report"
  ],
  "showRupee": [
    "Weightage Premium",
    "Total Premium",
    "Total Loan Amount"
  ],
  "showArrow": [
    "Premium Growth %"
  ],
  "showGrowth": [
    "Policies count"
  ],
  "Products": [
    {
      "name": "Motor",
      "product_type": "motor",
      "isSelected": false
    },
    {
      "name": "CV",
      "product_type": "cv",
      "isSelected": false
    },
    {
      "name": "Health",
      "product_type": "health",
      "isSelected": false
    },
    {
      "name": "Life",
      "product_type": "life",
      "isSelected": false
    },
    {
      "name": "Travel",
      "product_type": "travel",
      "isSelected": false
    },
    {
      "name": "SME",
      "product_type": "sme",
      "isSelected": false
    },
    {
      "name": "Rb Silver",
      "product_type": "rb_silver",
      "isSelected": false
    },
    {
      "name": "Rb Gold",
      "product_type": "rb_gold",
      "isSelected": false
    },
    {
      "name": "Rb Wellness",
      "product_type": "rb_wellness",
      "isSelected": false
    },
    {
      "name": "Rb Life Gold",
      "product_type": "rb_life_gold",
      "isSelected": false
    },
    {
      "name": "Rb Life Silver",
      "product_type": "rb_life_silver",
      "isSelected": false
    },
    {
      "name": "Rb Life Platinum",
      "product_type": "rb_life_platinum",
      "isSelected": false
    },
    {
      "name": "Rb Platinum",
      "product_type": "rb_platinum",
      "isSelected": false
    },
    {
      "name": "Rb Loan",
      "product_type": "rb_loan",
      "isSelected": false
    },
    {
      "name": "Rb Cards",
      "product_type": "rb_cards",
      "isSelected": false
    },
    {
      "name": "Rb Wellness Gold (L)",
      "product_type": "rb_life_wellness_gold",
      "isSelected": false
    },
    {
      "name": "Rb Wellness Platinum (L)",
      "product_type": "rb_life_wellness_platinum",
      "isSelected": false
    },
    {
      "name": "Rb Suraksha Gold",
      "product_type": "rb_suraksha_gold",
      "isSelected": false
    },
    {
      "name": "Rb Suraksha Platinum",
      "product_type": "rb_suraksha_platinum",
      "isSelected": false
    },
    {
      "name": "Rb Health Liberty",
      "product_type": "rb_health_liberty",
      "isSelected": false
    },
    {
      "name": "Rb Health Zuno",
      "product_type": "rb_health_zuno",
      "isSelected": false
    },
    {
      "name": "Auto Loan",
      "product_type": "auto_loan",
      "isSelected": false
    },
    {
      "name": "Rb Health Magma",
      "product_type": "rb_health_magma",
      "isSelected": false
    },
    {
      "name": "Rb Health Magma(M)",
      "product_type": "rb_health_magma_m",
      "isSelected": false
    },
    {
      "name": "RSA",
      "product_type": "rsa",
      "isSelected": false
    }
  ],
  "ALl_Products": {
    "product_type": [
      "motor",
      "cv",
      "health",
      "life",
      "travel",
      "sme",
      "rb_silver",
      "rb_gold",
      "rb_wellness",
      "rb_life_gold",
      "rb_life_silver",
      "rb_life_platinum",
      "rb_platinum",
      "rb_loan",
      "rb_cards",
      "rb_life_wellness_gold",
      "rb_life_wellness_platinum",
      "rb_suraksha_gold",
      "rb_suraksha_platinum",
      "rb_health_zuno",
      "rb_health_liberty",
      "auto_loan",
      "rb_health_magma",
      "rb_health_magma_m",
      "rsa"
    ]
  },
  "BETA_AV": "RBAV",
  "hide_colms": [
    "account_id",
    "product_type",
    "filter",
    "created_by"
  ],
  "hide_crumbs": [],
  "send_report_list": [
    "Except_cancelled",
    "Cancelled_policies"
  ],
  "col_show": {
    "Team report pending summary": [
      "Premium",
      "WP",
      "NOP"
    ],
    "Channel pending summary": [
      "Premium",
      "WP",
      "NOP"
    ],
    "Unit partners recruitment": [
      "LI",
      "GI"
    ],
    "Team partner recruitment": [
      "LI",
      "GI"
    ]
  },
  "month_date_picker": [
    "Unit partner activation revist",
    "Team wise partner activation revist",
    "Team business performance report revist",
    "Product wise last activation date",
    "Apex journey drop off's",
    "Team apex journey drop off's"
  ],
  "reset_month_filter": [
    "Partner Activation Dashboard Revist"
  ],
  "reporting_types": {
    "2": "Partner to Field RM",
    "8": "Partner to Health FRM",
    "9": "Partner to Life FRM",
    "20": "Partner to Motor Health Life FRM Hybrid",
    "21": "Partner to Motor Life FRM Hybrid",
    "23": "Partner to Health Life FRM Hybrid",
    "22": "Partner to Motor Health FRM Hybrid"
  },
  "businessTypes": [
    {
      "name": "New Business",
      "id": 1,
      "isSelected": false
    },
    {
      "name": "Rollover",
      "id": 2,
      "isSelected": false
    },
    {
      "name": "Renewal",
      "id": 3,
      "isSelected": false
    }
  ],
  "supervisorRoleList": ["supervisor"],
  "zsmRoleList": ["zsm"],
  "redirectionList": [
    {
      "name": "RB Suraksha",
      "value": "RB Suraksha",
      "url": "https://rbfinance.rbstaging.in/api_life/v1/life_validation/validate_token/",
      "dossier_redirection": true
    },
    {
      "name": "Motor-D2C",
      "value": "Motor-D2C",
      "url": "https://apex.renewbuyinsurance.in/api/v1/auth/agent_redirection/",
      "dossier_redirection": true
    },
    {
      "name": "Health-D2C",
      "value": "Health-D2C",
      "url": "https://api-apex-health.rbstaging.in/api/v1/auth/agent_redirection/",
      "dossier_redirection": true
    },
    {
      "name": "RB Health",
      "value": "RB Health",
      "url": "https://rbfinance.rbstaging.in/api/v2/token/validate_token/",
      "dossier_redirection": true
    }
  ],
  "paymaster_special_redirections": [
    {
      "name": "Special Approval-TW",
      "url": "https://paymasterv2.rbstaging.in/rules/special/motor",
    },
    {
      "name": "Special Approval-FW",
      "url": "https://paymasterv2.rbstaging.in/rules/special/motor4W",
    },
    {
      "name": "Special Approval-CV",
      "url": "https://paymasterv2.rbstaging.in/rules/special/cv",
    },
    {
      "name": "Special Approval-Health",
      "url": "https://paymasterv2.rbstaging.in/rules/special/health",
    },
    {
      "name": "Special Approval-Life",
      "url": "https://paymasterv2.rbstaging.in/rules/special/life",
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
