import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { JsonPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
// import {MatSort} from '@angular/material/sort';
// import { SidebarService } from './sidebar.services';
@Component({
  selector: 'app-reporteelist',
  templateUrl: './reporteelist.component.html',
  styleUrls: ['./reporteelist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReporteeListComponent implements OnInit {
  public navItem: any;
  public url: string;
  public id: any;
  public base: string;
  public toggle;
  public partnerData: any;
  public response: any;
  rowColumns: any;
  displayedColumns: string[];
  dataSource = new MatTableDataSource(this.response);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public showPagination = true;
  public paginationList: Array<any> = [];
  public selection: any;
  public reportingName: any;
  public requestRaised: any;
  public reportingCode: any;





  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private config: ConfigService,
    private auth: AuthService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog

  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id && params.unique_code && params.request_raised) {
        this.reportingName = decodeURIComponent(params.id);
        this.reportingCode  = params.unique_code;
        this.requestRaised = params.request_raised;
      }
    });
    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
          if (this.requestRaised === 'null') {
            this.requestRaised = null;
          }
          const requestedData = {
          unique_code: this.reportingCode,
          request_raised: this.requestRaised
        };
          this.api.reporteeDetailedView(requestedData).then((response) => {
          this.response = response;

          for (let i = 0; i < this.response.length; i++) {
            this.displayedColumns = Object.keys(response[i]);
          }
          this.displayedColumns = ['select'].concat(this.displayedColumns);
          this.response = new MatTableDataSource(this.response);
          this.selection = new SelectionModel(this.response);
          this.response.sort = this.sort;
          if (this.paginationList.indexOf(this.response) > -1) {
            this.showPagination = false;
          }
          // this.isAllSelected();
          // this.masterToggle();
          // this.checkboxLabel(this.response);

        });
      }
    });

    // this.paginationList = this.config.getText('hidePagination');
    this.paginationList = environment.hidePagination;

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.response.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.response.data.forEach(row => this.selection.select(row));
  }

  // /** The label for the checkbox on the passed row */
  // checkboxLabel(row) {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  // }

  acceptPartner() {
    let uniqueCode;
    const partnerCode = [];
    for (let k = 0; k < this.selection._selected.length; k++) {
      uniqueCode = this.selection._selected[k].unique_code;
      partnerCode.push(uniqueCode);
    }
    const acceptedData = {
      reportee: partnerCode,
      manager: this.reportingCode,
      is_accepted: true
    };
    this.api.acceptMemberList(acceptedData).then((response) => {
      this.router.navigate(['/manageteam']);
    });

  }

  rejectPartner() {
    let uniqueCode;
    const partnerCode = [];
    for (let k = 0; k < this.selection._selected.length; k++) {
      uniqueCode = this.selection._selected[k].unique_code;
      partnerCode.push(uniqueCode);
    }
    const acceptedData = {
      reportee: partnerCode,
      manager: this.reportingCode,
      is_accepted: false
    };
    this.api.acceptMemberList(acceptedData).then((response) => {
      this.router.navigate(['/manageteam']);
    });


  }
  ngOnInit() {

  }

}
