import { Injectable, NgZone } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
// import { ConfigService } from '../config/config.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../shared/dialogs/dialog/dialog.component';
import { AuthService } from '../services/auth.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from "rxjs";
import { environment } from '../../environments/environment';


// import 'rxjs/add/operator/catch';
import { LoaderService } from '../shared/loader/loader.service'


@Injectable()
export class HttpService {
    private inTheZone: boolean = true
    constructor(
        private http: Http,
        // private config: ConfigService,
        private dialog: MatDialog,
        private ngZone: NgZone,
        private loaderService: LoaderService,
        private auth: AuthService
    ) {

    }

    private header: Headers = new Headers({
        'Content-Type': 'application/json',
        // 'secret-key': this.config.get('secret-key'),
        // 'api-key': this.config.get('api-key')
        'secret-key': environment['secret-key'],
        'api-key': environment['api-key']
    })

    private headerFile: Headers = new Headers({
        // 'secret-key': this.config.get('secret-key'),
        // 'api-key': this.config.get('api-key')
        'secret-key': environment['secret-key'],
        'api-key': environment['api-key']
    })

    private headerProd: Headers = new Headers({
        'Content-Type': 'application/json',
        'secret-key': environment['secret-key'],
        'api-key': environment['api-key']
    })

    private Seturl(url: string) {
        // return this.config.get('api') + url
        return environment['api'] + url
    }

    private Domainurl(url: string) {
        // return this.config.get('amsURL') + url
        return environment['amsURL'] + url
    }

    GetRequest(url: string, header?: Headers) {
        this.setToken()
        this.showLoader();
        url = this.Seturl(url)
        return this.http.get(url, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }

    // GetRequestData(url: string, data: any, header?: Headers) {        
    //     this.setToken()
    //     this.showLoader();
    //     url = this.Seturl(url)
    //     return this.http.get(url, { headers: this.header, body: JSON.stringify(data) })
    //         .pipe(map((res: Response) => res.json()),
    //             catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
    //             finalize(() => { this.onEnd(); }));
    // }

    GetRequestProd(url: string, header?: Headers) {
        this.setToken()
        this.showLoader();
        url = this.Seturl(url)
        return this.http.get(url, { headers: this.headerProd })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }


    GetSearchRequest(url: string, header?: Headers) {
        this.setToken()
        url = this.Seturl(url)
        return this.http.get(url, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }


    PostRequest(url: string, data: any, header?: Headers) {
        this.setToken()
        this.showLoader();
        url = this.Seturl(url)
        return this.http.post(url, data, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }

    PostRequestHead(url: string, data: any, header?: Headers) {
        this.setToken()
        this.showLoader();
        url = this.Seturl(url)
        return this.http.post(url, data, { headers: this.headerFile })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }

    PostRequestHeadUrl(url: string, data: any, header?: Headers) {
        this.setToken()
        this.showLoader();
        url = this.Domainurl(url)
        return this.http.post(url, data, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => { return throwError(this.errorHandler(e.json())) }),
                finalize(() => { this.onEnd(); }));
    }

    errorHandler(err) {
        if (this.inTheZone) {
            let error
            if (err.error) {
                error = err.error
            } else if (err.details) {
                error = err.detail
            } else if (err.status == 0 && err._body.currentTarget.status == 0 || err.currentTarget && err.currentTarget.status == 0) {
                error = 'Error response from dialer'
            } else {
                error = err
            }
            this.inTheZone = false
            this.ngZone.run(() => {
                let dialogRef = this.dialog.open(DialogComponent, {
                    width: '350px',
                    data: {
                        title: "Error",
                        msg: error,
                        callback: () => { this.inTheZone = true }
                    }
                });
            })
        }
    }

    setToken() {
        if (this.header.has('Authorization')) {
            this.header.delete('Authorization');
        }
        if (this.headerFile.has('Authorization')) {
            this.headerFile.delete('Authorization');
        }

        if (this.auth.GetUserToken()) {
            this.header.set('Authorization', 'Token ' + this.auth.GetUserToken())
            this.headerFile.set('Authorization', 'Token ' + this.auth.GetUserToken())
        } else {
            this.header.delete('Authorization')
            this.headerFile.delete('Authorization')
        }
    }
    private onEnd(): void {
        this.hideLoader();
    }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    }


    getRequest(url: string, header?: Headers) {
        this.setToken();
        this.showLoader();
        return this.http.get(url, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }

    postRequest(url: string, data: any, header?: Headers) {
        this.setToken();
        this.showLoader();
        return this.http.post(url, data, { headers: this.header })
            .pipe(map((res: Response) => res.json()),
                catchError((e: Response) => Observable.throw(this.errorHandler(e.json()))),
                finalize(() => { this.onEnd(); }));
    }

}
