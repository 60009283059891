import { Component, OnInit, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router'
// import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { JsonPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
//import {MatSort} from '@angular/material/sort';
//import { SidebarService } from './sidebar.services';
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeamComponent implements OnInit {
  public navItem: any
  public url: string
  public id: any
  public base: string
  public toggle
  public partnerData: any
  public response: any
  rowColumns: any
  displayedColumns: string[];
  dataSource = new MatTableDataSource(this.response);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public showPagination: boolean = true;
  public paginationList: Array<any> = [];
  public selection: any
  public reportingName: any;
  public requestRaised: any;
  public reportingCode: any;
  public inTheZone: boolean = true
  public loading: boolean = true


  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private config: ConfigService,
    private auth: AuthService,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    private dialog: MatDialog

  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['end_date']) {
        this.url = "?start_date=" + params['start_date'] + "&end_date=" + params['end_date']
      }
    });
    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.api.getPartnersList().then((response) => {
          this.loading = true;
          if (response.constructor !== Array) {
            this.loading = false;
            let dialogRef = this.dialog.open(DialogComponent, {
              width: '350px',
              data: {
                title: response['response'],
              }
            });
          }
          else {
            this.response = response;
            if (this.response.constructor === Array) {
              this.loading = false;
              for (var i = 0; i < this.response.length; i++) {
                response[i]["Action"] = this.domSanitizer.bypassSecurityTrustHtml(
                  '<div style="cursor: pointer;" reportingCode=' + response[i]["name"].split(/[()]/)[1] + ' requestRaised=' + response[i]["request_raised_code"] + ' id=' + encodeURIComponent(response[i]["name"].split(/[()]/)[0].trim()) + ' onclick="reporteeListRoute(event)"><img  img width="28" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Open_Iconic_arrow_circle_right.svg/120px-Open_Iconic_arrow_circle_right.svg.png"></div>');
                this.displayedColumns = Object.keys(response[i])
                this.displayedColumns.splice(4, 1);
              }
              this.response = new MatTableDataSource(this.response);
              setTimeout(() => {
                this.response.paginator = this.paginator;
                this.response.sort = this.sort;
              })
              if (this.paginationList.indexOf(this.response) > -1) {
                this.showPagination = false
              }
            }
          }
        })
      }
    })
    this.windowFunction();
    // this.paginationList = this.config.getText("hidePagination")
    this.paginationList = environment.hidePagination

  }
  reporteeListRoute = (event) => {
    this.reportingName = event.currentTarget.id;
    this.requestRaised = event.currentTarget.attributes[2].value;
    this.reportingCode = event.currentTarget.attributes[1].value;

    if (this.inTheZone) {
      this.inTheZone = false
      this.ngZone.run(() => {
        this.router.navigate(['/reporteelist'], { queryParams: { 'id': this.reportingName, 'unique_code': this.reportingCode, 'request_raised': this.requestRaised } });
      })
    }

  }
  windowFunction() {
    window['reporteeListRoute'] = this.reporteeListRoute
  }

  ngOnInit() {

  }

}
